import { useState } from "react";

type Props = {
  setLoginInfo: any;
};

function Login({ setLoginInfo }: Props) {
  const [id, setId] = useState("");
  const [pw, setPw] = useState("");

  const handleLogin = () => {
    if (id === "guest" && pw === "inforience!") {
      localStorage.setItem("chart-infori-auth", "inforience");
      setLoginInfo("inforience");
    } else {
      alert("ID, PW 를 다시 한 번 확인해주세요.");
      localStorage.removeItem("chart-infori-auth");
      setLoginInfo(null);
    }
  };

  return (
    <section className="hero is-primary is-fullheight">
      <div className="hero-body">
        <div className="container">
          <div className="columns is-centered">
            <div className="column is-5-tablet is-4-desktop is-3-widescreen">
              <span className="title">Inforience Chart</span>
              <form action="" className="box">
                <div className="field">
                  <label className="label">Username</label>
                  <div className="control has-icons-left">
                    <input
                      type="text"
                      placeholder="Username"
                      className="input"
                      required
                      onChange={(e) => setId(e.target.value)}
                    />
                    <span className="icon is-small is-left">
                      <i className="fa fa-person"></i>
                    </span>
                  </div>
                </div>
                <div className="field">
                  <label className="label">Password</label>
                  <div className="control has-icons-left">
                    <input
                      type="password"
                      className="input"
                      required
                      onChange={(e) => setPw(e.target.value)}
                    />
                    <span className="icon is-small is-left">
                      <i className="fa fa-lock"></i>
                    </span>
                  </div>
                </div>
                <div className="field">
                  <button className="button is-success" onClick={handleLogin}>
                    Login
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Login;
